import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter, useHistory, useParams, Redirect, Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Label } from "reactstrap";

import AuthWrapper from "./AuthWrapper";
import { PasswordInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES, getParamsAttachedRoute } from "helpers/routeHelpers";
import { ResetPassword } from "api/api.service";
import forgetImage from "assets/images/reset-pass-img.png";
import { PageHeadings, PageTitles } from "../../constants";
import Headings from "./Headings";
import { CustomModal } from "../../components/CustomModal";
import successImg from "../../assets/images/app/common/modal-success-img.svg";
 
const ResetPasswordView = (props) => {
    let {} = props;

    const history = useHistory();
    const { code, tokenId } = useParams();
    let isAuthenticated = useSelector((state) => state.userAuth.isAuthenticated);

    const [isLoading, setIsLoading] = useState(false);
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            // Alpha
            .required("Enter New Password")
            .min(6, "Password should be minimum 6 characters long")
            .max(25, "Password should be between 6 to 25 characters")
            .test(
                "regex",
                "Password must contains characters, special sharacters and numbers",
                (val) => {
                    let regExp = new RegExp(
                        "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
                    );

                    return regExp.test(val);
                }
            ),
            confirmPassword: Yup.string()
            .oneOf(
                [Yup.ref("newPassword")],
                "Please make sure your passwords match"
            )
            // Alpha
            .required("Please Enter Your Confirm Password")
        }),

        onSubmit: (values) => {
            console.log('values', values)
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        // Alpha
        setShowResetSuccessModal(true);

        return;
        let { confirmPassword } = values;

        try {
            setIsLoading(true);
            let body = {
                Code: code,
                TokenUUID: tokenId,
                Password: confirmPassword
            };
            let res = await ResetPassword(body);
            toast.success("Password has been reset successfully !");
            history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN));
        } finally {
            setIsLoading(false);
        }
    };

    if (isAuthenticated) {
        return <Redirect to={APP_ROUTES.BLS_FEEDBACK} />;
    }
    console.log('formik', formik)
    return (
        <>
            <Helmet>
                <title>{PageTitles.RESET_PASSWORD}</title>
            </Helmet>
            <AuthWrapper>
                <div className="bv-forget-wrap">
                    <Headings title="Reset Password" subText="Set Your New Password" />
                    <form className="mt-5" onSubmit={formik.handleSubmit}>
                        <Label>New Password</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder="Enter Password"
                                type="password"
                                name="newPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.newPassword}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? (
                                <span className="error">{formik.errors.newPassword}</span>
                            ) : null}
                        </div>

                        <Label>Confirm Password</Label>
                        <div className="mb-4">
                            <PasswordInput
                                placeholder="Enter Password Again"
                                type="password"
                                name="confirmPassword"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.confirmPassword}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                <span className="error">{formik.errors.confirmPassword}</span>
                            ) : null}
                        </div>

                        <CustomButton
                            loading={isLoading}
                            color="primary"
                            type={"submit"}
                            title="Reset Password"
                            className="w-100"
                        />
                        <div>
                            <Link
                                className="d-flex align-items-center justify-content-center my-4"
                                to={APP_ROUTES.LOGIN}
                            >
                                <p className="standard-typo-14-600"> Back to Login </p>
                            </Link>
                        </div>
                    </form>
                </div>
                <CustomModal isOpen={showResetSuccessModal} size="md" modalBodyClasses="px-5">
                    <div className="text-center px-5">
                        <img src={successImg} className="mb-4" />
                        <h4 className="font-clr-theme-secondary font-family-lexend">
                        Password Reset Successfully
                        </h4>
                        <p className="my-4 font-family-lexend fw-500 font-size-14">
                        Your password has been reset.
                        </p>
                        <CustomButton
                            color="primary"
                            title="Back to Login"
                            className="custom-btn-sm w-100 mb-5"
                            onClick={() => history.push(getParamsAttachedRoute(APP_ROUTES.LOGIN))}
                        />
                    </div>
                </CustomModal>
            </AuthWrapper>
        </>
    );
};

export default withRouter(ResetPasswordView);
