import * as Yup from "yup";


export const initialValues= {
  name: "",
  email: "",
  password: "",
  newPassword: "",
  confirmPassword: ""
}

export const validationSchema = Yup.object({
  name: Yup.string().required("Please Enter Your Name")
  .min(3, "Name is too short!")
  .max(50, "Name is too long!"),
  email: Yup.string()
      .email("Enter a valid email address")
      .required("Enter email address")
      .min(6, "Too short email!")
      .max(50, "Too long email!")
      .matches(
          /([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/,
          "Enter valid email address"
      ),
  password: Yup.string().required("Enter Password"),
  // .min(6, "Password should be minimum 6 characters long")
  // .max(25, "Password should be between 6 to 25 characters")
  // .test(
  //     "regex",
  //     "Password must contain characters, special characters and numbers",
  //     (val) => {
  //         let regExp = new RegExp(
  //             "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
  //         );
  //         return regExp.test(val);
  //     }
  // ),
  newPassword: Yup.string()
  // Alpha
  .required("Enter New Password")
  .min(6, "Password should be minimum 6 characters long")
  .max(25, "Password should be between 6 to 25 characters")
  .test(
      "regex",
      "Password must contains characters, special sharacters and numbers",
      (val) => {
          let regExp = new RegExp(
              "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])|(?=.*[A-Z]).{8,}$"
          );
          return regExp.test(val);
      }
  ),
  confirmPassword: Yup.string()
  // Alpha
  .oneOf(
      [Yup.ref("newPassword")],
      "Please make sure your passwords match"
      )
      .required("Please Enter Your Confirm Password")
})