import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { connect, useSelector } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { Config } from "../../../themeConfig";
import { ConfirmationModal } from "../../CustomModal";
import { ThemeColor } from "../../../constants";
import { getMediaPath } from "utils/mediaUtils";
import { SIGN_OUT } from "store/actions";
import APP_CONFIG from "APP_CONFIG";
import modalLogoutImg from "../../../assets/images/app/common/modal-logout-img.svg";
import { APP_ROUTES } from "../../../helpers/routeHelpers";
import LogoutIcon from '../../../assets/images/app/common/aou-logout-icon.svg';

const ProfileMenu = (props) => {
    const history = useHistory()
    const { user } = useSelector((state) => state?.userAuth);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [menu, setMenu] = useState(false);
    const [userName, setUserName] = useState("Admin");

    const selectedTheming = Config.ThemeName;
    const currentTheme = ThemeColor[selectedTheming];

    const handleLogoutModal = () => {
        setShowLogoutModal(true);
    };

    const handleSignout = () => {
        const { signOut } = props;
        localStorage.clear();
        signOut();
    };

    const handleEditProfile = () => {
        history.push(APP_ROUTES.EDIT_PROFILE)
    }

    return (
        <>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle
                    className="btn header-item "
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <img
                        className="rounded-circle header-profile-user"
                        src={getMediaPath(user?.Media) || APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER} //currentTheme.userImg.default
                        alt="Header Avatar"
                    />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{userName}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {/* <DropdownItem tag="a" href="/profile">
                        {"My Profile"}
                    </DropdownItem> */}
                    <div className="" />
                    <div className="dropdown-item" role="button" onClick={handleEditProfile}>
                        <span>{"Edit Profile"}</span>
                    </div>
                    <div className="dropdown-item" role="button" onClick={handleLogoutModal}>
                        <img src={LogoutIcon} width={20} height={20} className="me-2" />
                        <span>{"Logout"}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>

            <ConfirmationModal
                isOpen={showLogoutModal}
                hasCustomConfig
                size="md"
                onNo={() => setShowLogoutModal(false)}
                onYes={() => handleSignout()}
                customTitle="Are you sure you want to logout?"
                customYesBtnText="Logout"
                customNoBtnText="Cancel"
                heading="Logout"
                // customImageSrc={modalLogoutImg}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch({ type: SIGN_OUT })
});

export default connect(null, mapDispatchToProps)(withRouter(ProfileMenu));

ProfileMenu.propTypes = {
    signOut: PropTypes.any
};
