import totalUsersImg from "assets/images/app/dashboard-page/total-users.svg";
import AvatarOne from '../assets/images/app/aou-cusmtomer-management/avatar-one.svg';
import AvatarTwo from '../assets/images/app/aou-cusmtomer-management/avatar-two.svg';
import AvatarThree from '../assets/images/app/aou-cusmtomer-management/avatar-three.svg';
import AvatarFour from '../assets/images/app/aou-cusmtomer-management/avatar-four.svg';
import AvatarFive from '../assets/images/app/aou-cusmtomer-management/avatar-five.svg';
import AvatarSix from '../assets/images/app/aou-cusmtomer-management/avatar-six.svg';
import AvatarSeven from '../assets/images/app/aou-cusmtomer-management/avatar-seven.svg';
import AvatarEight from '../assets/images/app/aou-cusmtomer-management/avatar-eight.svg';
import AvatarNine from '../assets/images/app/aou-cusmtomer-management/avatar-nine.svg';



const DashboardModule = [
        {
            id: 1,
            title: "John Smith",
            iconUrl: AvatarNine,
            date: "15 Aug, 2023",
            rating: 0,
            email: "john11@mail.com",
            phone: "(225) 555-0118",
            plan: "free",
            status: "active",
        },
        {
            id: 2,
            title: "Cody Fisher",
            iconUrl: AvatarOne,
            date: "20 Aug, 2023",
            rating: 2,
            email: "michael.mitc@example.com",
            phone: "(406) 555-0120",
            plan: "premium",
            status: "active",
        },
        {
            id: 3,
            title: "Jane Cooper",
            iconUrl: AvatarTwo,
            date: "23 Aug, 2023",
            rating: 7,
            email: "tim.jennings@example.com",
            phone: "(229) 555-0109",
            plan: "premium",
            status: "Inactive",
        },
        {
            id:4, 
            title: "Kristin Watson",
            iconUrl: AvatarThree,
            date: "28 Aug, 2023",
            rating: 9,
            email: "willie.jennings@example.com",
            phone: "(205) 555-0100",
            plan: "free",
            status: "Inactive",
        },
        {
            id:5, 
            title: "Arlene McCoy",
            iconUrl: AvatarFour,
            date: "30 Aug, 2023",
            rating: null,
            email: "jessica.hanson@example.com",
            phone: "(307) 555-0133",
            plan: "free",
            status: "active",
        },
        {
            id:6, 
            title: "Dianne Russell",
            iconUrl: AvatarFive,
            date: "03 Sep, 2023",
            rating: undefined,
            email: "nathan.roberts@example.com",
            phone: "(219) 555-0114",
            plan: "free",
            status: "active",
        },
        {
            id: 7,
            title: "Annette Black",
            iconUrl: AvatarSix,
            date: "15 Sep, 2023",
            rating: 8,
            email: "bill.sanders@example.com",
            phone: "(209) 555-0104",
            plan: "premium",
            status: "active",
        },
        {
            id: 8,
            title: "Devon Lane",
            iconUrl: AvatarSeven,
            date: "18 Sep, 2023",
            rating: 6,
            email: "nevaeh.simmons@example.com",
            phone: "(319) 555-0115",
            plan: "free",
            status: "Inactive",
        },
        {
            id: 9,
            title: "Jacob Jones",
            iconUrl: AvatarEight,
            date: "19 Sep, 2023",
            rating: 2,
            email: "curtis.weaver@example.com",
            phone: "(505) 555-0125",
            plan: "free",
            status: "Inactive",
        },
        {
            id: 10,
            title: "Arlene McCoy",
            iconUrl: AvatarNine,
            date: "25 Sep, 2023",
            rating: 1,
            email: "michael.mitc@example.com",
            phone: "(406) 555-0120",
            plan: "premium",
            status: "active",
        },
];

export default DashboardModule;
