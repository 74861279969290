import { useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { PageTitles, Plans } from "../../constants";
import typography from "./typography";
import {HeadingWithBackArrow} from "../../components/HeadingWithBackArrow";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import ProfileImage from '../../assets/images/app/common/aou-profile-img.png';
import { Heading } from 'components/Heading';
import DetailsBox from "./DetailsBox";
import { ConfirmationModal } from "../../components/CustomModal";
import ModalSuccessImage from '../../assets/images/app/common/aou-modal-success-img.svg'

import "./Styles.scss";

const toggleConfig = {
  trueContent: typography.ACTIVE,
  falseContent: typography.INACTIVE,
}

const profileBioConfig = [
  {
    id: 0,
    title: typography.NAME,
    value: "John Smith"
  },
  {
    id: 1,
    title: typography.EMAIL,
    value: "jogn11@mail.com"
  },
  {
    id: 2,
    title: typography.OCCUPATION,
    value: "Engineer"
  },
  {
    id: 3,
    title: typography.GENDER,
    value: "Male"
  },
  {
    id: 4,
    title: typography.MEMBERSHIP_PLAN,
    value: "premium"
  },
  {
    id: 5,
    title: typography.PHONE,
    value: "(225) 555-0118"
  },
  {
    id: 6,
    title: typography.DATE_OF_BIRTH,
    value: "11 Apr, 1998"
  },
  {
    id: 7,
    title: typography.AGE,
    value: "28 Years"
  }
]

const profileDetailsConfig = [
  {
    id: 0,
    date: "Sep 06, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 1,
    date: "Sep 15, 2023",
    category: "Action",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 2,
    date: "Sep 19, 2023",
    category: "Power",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 3,
    date: "Sep 22, 2023",
    category: "Power",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 4,
    date: "Sep 29, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  },
  {
    id: 5,
    date: "Oct 08, 2023",
    category: "Action",
    BLSDuration: "90 Sec",
    feedback: "Not Submitted" 
  },
  {
    id: 6,
    date: "Oct 16, 2023",
    category: "Responsibility",
    BLSDuration: "90 Sec",
    feedback: "Submitted" 
  }
]

export default function Details () {
  const history = useHistory()

  const [isModalActive, setIsModalActive] = useState(false)
  const [isCustomerActive, setIsCustomerActive] = useState(false)
  const backButtonHandler = () => {
    history.push(APP_ROUTES.CUSTOMER_MANAGEMENT)
  }

  const modalToggleHandler = () => {
    setIsModalActive(!isModalActive)
  }
  const updateUserStatus = () => {
    setIsCustomerActive(!isCustomerActive)
    modalToggleHandler()
  }

  return(
    <>
      <div className="page-content">
        <Helmet>
            <title>{PageTitles.CUSTOMER_DETAILS}</title>
        </Helmet>
        <Container fluid className="customer-details">
          <HeadingWithBackArrow
          backArrowClickHandler={backButtonHandler}
          headingTitle={typography.CUSTOMER_DETAILS}
          toggleButtonConfig= {toggleConfig}
          toggleValue= {isCustomerActive}
          toggleHandler={ modalToggleHandler}
           />
           <div className="profile-bio-con">
            <div  className="profile-img-con mb-4 p-3">
              <div className="profile-img">
                <img
                    src={
                        ProfileImage
                    }
                    alt={"profile"}
                />
              </div>
            </div> 
            <Row className="profile-bio-typo p-3">
              {profileBioConfig.map( el => 
                    <Col key={el.id} xs="6" xxl="3" xl="4" md="4" sm="6" className="mb-5 " >
                        <h6 className="standard-typo-14-400">{el.title}</h6>
                        <p className={`${el.id === 4 ? Plans.getLabelClass(el.value): ""} standard-typo-16-600`}>{el.id === 4 ? Plans.getDisplayTextKey(el.value): el.value}</p>
                    </Col>                  
              )}
            </Row>
           </div>
           <div className="profile-details-con p-4 mt-3">
            <div className="mb-3">
              <Heading heading={typography.AFFIRMATION_DETAILS} />
            </div>
            <Row className="mx-auto">
              {profileDetailsConfig.map(el => {
                return(
                  <>
                    <Col md="6" xl="4" xxl="4" className=" mb-4 px-0 pe-4" key={el.id}>
                      <DetailsBox 
                      data={el}/>
                    </Col>
                  </>
                )
              })}
            </Row>
           </div>
        </Container>
        <ConfirmationModal
          isOpen={isModalActive}
          hasCustomConfig
          size="md"
          onNo={() => setIsModalActive(false)}
          onYes={updateUserStatus}
          customTitle={typography.STATUS_UPDATE_CONFIRMATION_MESSAGE}
          customYesBtnText={typography.YES}
          customNoBtnText={typography.NO}
          customImageSrc={ModalSuccessImage}
        />
      </div>
    </>
  )
}