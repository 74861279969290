import { RatingBarInput } from "../../components/RatingBarInput";
import typography from "./typography";

export default function Details({
  rating=1
}){
  return(
    <>
      <div className="bls-feedback-con px-5">
        <h1 className="heading standard-heading-22-600">{typography.FEEDBACK_DETAILS}</h1>
        <div className="after-heading">
          <div className="name-con">
            <p className="standard-typo-14-400">Customer Name: <span >John Smith</span></p>
          </div>
          <div>
            <p className="standard-typo-14-400">Submitted On: <span >15 Aug, 2023</span></p>
          </div>
        </div>
        <div className="question-answer-con">
          <p className="question">How do you feel after completing the BLS exercise and repeating the positive affirmation?</p>
          <p className="answer">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, or sit amet, consectetur adipiscing elit,</p>
          <p className="question">On a scale of 1-10, how much did the BLS and affirmation help in managing your distressing mood?</p>
          <RatingBarInput 
          rating={rating}
          innerClass="inner-con"
          containerClass="outer-con"
          mainClass="answer"
          icon={true}/>
          <p className="question">Did the BLS and affirmation help shift your thoughts or emotions in a more positive direction?</p>
          <p className="answer">Yes</p>
          <p className="question">Do you feel more grounded or present in the moment after completing the exercise?</p>
          <p className="answer">No</p>
          <p className="question">Do you feel lighter? calmer?</p>
          <p className="answer">Yes</p>
          <p className="question">Are there any specific thoughts or beliefs that still feel distressing, even after completing the BLS and affirmation?</p>
          <p className="answer">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo</p>
        </div>
      </div>
    </>
  )
}