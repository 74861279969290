import { useState } from "react";
import APP_CONFIG from "../../APP_CONFIG";
import { UserAvatar } from "../../components/Avatar";
import DataTable from "../../components/DataTable/DataTable";
import { Pagination } from "../../components/Pagination";
import DashboardModule from "../../mock-data/DashboardModule";
import { truncateString } from "../../utils/commonUtils";
import { getMediaPath } from "../../utils/mediaUtils";
import typography from "./typography";
import Config from "../../Config";
import { SortOrder } from "../../constants";
import { findBarPercentage } from "./Utility";
import viewIcon from "../../assets/images/app/common/aou-view-icon.svg";
import { CustomModal } from "../../components/CustomModal";
import Details from "./Details";
import { RatingBarInput } from "../../components/RatingBarInput";
import CloseIcon from '../../assets/images/app/common/aou-close-icon.svg';
const SORTABLE_COLUMNS = {
  [typography.CUSTOMER]: "customer",
  [typography.DATE]: "date",
};

export default function BLSTable () {

  const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [selectedRating, setSelectedRating] = useState(1)
    const [limit, setLimit] = useState(Config.LIMIT)
    const [isModalActive, setIsModalActive] = useState(false)

    const modalToggleHandler = (rating) => {
        setSelectedRating(rating)
        setIsModalActive(!isModalActive)
    }
    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleSortingChange = (limit) => { 
        setLimit(limit);
    };

  return(
    <>
    <DataTable
    data={DashboardModule}
    currentSortOrder={currentSortOrder}
    currentSortColumn={currentSortColumn}
    onSortClick={handleSortClick}
    config={[
        {
            sortColumn: SORTABLE_COLUMNS[typography.CUSTOMER],
            isSortable: true,
            title: typography.CUSTOMER,
            render: (data) => (
                <div className="d-flex align-items-center">
                    <UserAvatar
                        imgSrc={
                            data.iconUrl ||
                            APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                        }
                        altText={"avatar"}
                    />
                    <span className="ms-2 standard-typo-14-400">{truncateString(data?.title, 30) || "-"}</span>

                </div>
            )
        },

        {
            sortColumn: SORTABLE_COLUMNS[typography.DATE],
            isSortable: true,
            title: typography.DATE,
            render: (data) => <div className="py-3 standard-typo-14-400">{data.date || "-"}</div>
        },

        {
            isSortable: false,
            title: typography.RATING,
            render: (data) => (
                <RatingBarInput
                rating={data.rating}
                containerClass={"rating-bar-con"}
                innerClass={"rating-bar-inner"}
                 />
            )
        },
        {
            title: typography.FEEDBACK_FORM,
            render: (data) => {
                return (
                    <div className="py-3 d-flex view-form-con" onClick={() => modalToggleHandler(data.rating)}>
                        <div className="view-form-icon-con" >
                        <img
                            src={viewIcon}
                            alt={"view icon"}
                            role="button"
                        />
                        </div>
                        <span className="standard-typo-14-400">View Form</span>
                    </div>
                );
            }
        }
    ]} />
     <Pagination
    onPageClick={() => {}}
    currentPage={1}
    totalData={100}
    handleChange={(e) => handleSortingChange(e.target.value)}
    rowLimit={limit}
    />
    <CustomModal
    isOpen={isModalActive}
    onClose={() => setIsModalActive(!isModalActive)}
    size="xl"
    modalBodyClasses="pt-0"
    headerClass="p-0 pe-3 pt-2"
    closeIcon={CloseIcon}
    >
        <Details rating={selectedRating} />
    </CustomModal>
    </>
  )
}