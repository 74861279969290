import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container} from "reactstrap";
import { useHistory } from "react-router-dom";

import { Heading } from "components/Heading";
import { PageHeadings, SortOrder, PageTitles, Plans } from "../../constants";
import typography from "./typography";
import Header from "./Header";
import Config from "../../Config";
import DataTable from "../../components/DataTable/DataTable";
import { UserAvatar } from "../../components/Avatar";
import { getMediaPath } from "../../utils/mediaUtils";
import APP_CONFIG from "../../APP_CONFIG";
import { truncateString } from "../../utils/commonUtils";
import viewIcon from "../../assets/images/app/common/aou-view-icon.svg";
import DashboardModule from "../../mock-data/DashboardModule";
import { ToggleInput } from "../../components/ToggleInput";
import { Pagination } from '../../components/Pagination';
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { ConfirmationModal } from "../../components/CustomModal";
import ModalSuccessImage from '../../assets/images/app/common/aou-modal-success-img.svg'

const SORTABLE_COLUMNS = {
  [typography.NAME]: "name",
};


const CustomerManagement = ({}) => {
  const history = useHistory()

  const [selectedStatusValue, setSelectedStatusValue] = useState(1)
  const [selectedPlanValue, setSelectedPlanValue] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [isModalActive, setIsModalActive] = useState(false)
  const [isCustomerActive, setIsCustomerActive] = useState(false)

  const modalToggleHandler = () => {
    setIsModalActive(!isModalActive)
  }
  const updateUserStatus = () => {
    setIsCustomerActive(!isCustomerActive)
    modalToggleHandler()
  }

  const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [limit, setLimit] = useState(Config.LIMIT)
    
    const handleSortClick = (item) => {
        setCurrentSortColumn(item.sortColumn);
        setCurrentSortOrder((prev) =>
            prev === SortOrder.ASC || !prev ? SortOrder.DESC : SortOrder.ASC
        );
    };

    const handleSortingChange = (limit) => {
        setLimit(limit);
    };

    const handleDetailsView = (id) => {
      history.push(`${APP_ROUTES.CUSTOMER_DETAILS}/${id}`)
    }
    return (
        <>
            <div className="page-content">
                <Helmet>
                    <title>{PageTitles.CUSTOMER_MANAGEMENT}</title>
                </Helmet>
                <Container fluid className="bls-feedback customer-management">
                    <Heading heading={PageHeadings.CUSTOMER_MANAGAMENT} />
                    <Header
                    statusFilterVal={selectedStatusValue}
                    statusFilterUpdateHandler={setSelectedStatusValue}
                    planFilterUpdateHandler={setSelectedPlanValue}
                    planFilterVal={selectedPlanValue}
                    searchTextVal={searchText}
                    searchValUpdateHandler={setSearchText}
                     />
                    <DataTable
                    data={DashboardModule}
                    currentSortOrder={currentSortOrder}
                    currentSortColumn={currentSortColumn}
                    onSortClick={handleSortClick}
                    config={[
                      {
                          sortColumn: SORTABLE_COLUMNS[typography.NAME],
                          isSortable: true,
                          title: typography.NAME,
                          render: (data) => (
                              <div className="d-flex align-items-center">
                                  <UserAvatar
                                      imgSrc={
                                          // getMediaPath(data.iconUrl) ||
                                          data.iconUrl ||
                                          APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER
                                      }
                                      altText={"avatar"}
                                  />
                                  <span className="ms-2 standard-typo-14-400">{truncateString(data?.title, 30) || "-"}</span>

                              </div>
                          )
                      },
                      {
                          isSortable: false,
                          title: typography.EMAIL,
                          render: (data) => <div className=" standard-typo-14-400">{data.email || "-"}</div>
                      },
                      {
                        isSortable: false,
                        title: typography.PHONE,
                        render: (data) => <div className=" standard-typo-14-400">{data.phone || "-"}</div>
                      },
                      {
                        isSortable: false,
                        title: typography.PLAN,
                        render: (data) => 
                        <div className="">
                          <div className={` standard-typo-14-400  ${Plans.getLabelClass(data.plan)}`}>{data.plan || "-"}</div>
                        </div>
                      },
                      {
                        isSortable: false,
                        title: typography.STATUS,
                        render: (data) =>
                        <div className="">
                          <ToggleInput 
                          defaultChecked={data.status !== "Inactive"}
                          trueContent= {typography.ACTIVE}
                          falseContent={typography.INACTIVE}
                          />          
                        </div>        
                      },
                      {
                          title: typography.ACTION,
                          render: (data) => {
                              return (
                                  <div className=" d-flex">
                                      <div className="view-form-icon-con" onClick={(e) => handleDetailsView(data.id)}>
                                      <img
                                          src={viewIcon}
                                          alt={"view icon"}
                                          role="button"
                                      />
                                      </div>
                                  </div>
                              );
                          }
                      }
                    ]} />
                    <Pagination
                    onPageClick={() => {}}
                    currentPage={1}
                    totalData={100}
                    handleChange={(e) => handleSortingChange(e.target.value)}
                    rowLimit={limit}
                    />
                </Container>
            </div>
            {/* <ConfirmationModal
                isOpen={isModalActive}
                hasCustomConfig
                size="md"
                onNo={() => setIsModalActive(false)}
                onYes={updateUserStatus}
                customTitle="Are you sure you want to Update user status?"
                customYesBtnText="Yes"
                customNoBtnText="No"
                customImageSrc={ModalSuccessImage}
                /> */}
        </>
    );
};

export default CustomerManagement;
