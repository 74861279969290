import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { Col, Container, Label, Row } from "reactstrap";
import { PageTitles } from "../../constants";
import {HeadingWithBackArrow} from "../../components/HeadingWithBackArrow";
import typography from "./typography";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { PasswordInput, TextInput } from "../../components/Input";
import { initialValues, validationSchema } from "./FormikConfig";
import FormikErrorText from "../../components/FormikErrorText/FormikErrorText";
import APP_CONFIG from "../../APP_CONFIG";
import { ImageBox } from "../../components/ImageBox";
import { ConfirmationModal } from "../../components/CustomModal";
import ModalSuccessImage from '../../assets/images/app/common/aou-modal-success-img.svg'
import toast from "react-hot-toast";

export default function EditProfile() {
  const history = useHistory()

  const [imageFile, setImageFile] = useState("");
  const [updatedConfirmation, setUpdateConfirmation] = useState(false)
  const { FullName, Email, ProfileImage } = useSelector((store) => store?.userAuth?.user);

  const backButtonHandler = () => {
    history.push(APP_ROUTES.BLS_FEEDBACK)
  }

  const updateButtonHandler = () => {
    toast.success("Profile has been updated successfully.");
    history.push(APP_ROUTES.BLS_FEEDBACK)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setUpdateConfirmation(true)
    }
  })

  const handleSubmit = (values) => {
    console.log('values: ', values)
  }

  return(
    <>
      <div className="page-content">
        <Helmet>
            <title>{PageTitles.EDIT_PROFILE}</title>
        </Helmet>
        <Container fluid className="edit-profile">
          <form className="mt-5" onSubmit={formik.handleSubmit}>
          <HeadingWithBackArrow
            backArrowClickHandler={backButtonHandler}
            headingTitle={typography.EDIT_PROFILE}
            buttonTitle={typography.UPDATE}
            buttonHandler ={() => setImageFile(true)}
           />
           <div className="edit-profile-content p-5 pb-2">
            <Row>
              <Col xs="12" md="6" lg="6" xl="4">
              <div className=" mb-4">
                <ImageBox
                    containerClasses="w-100 h-100"
                    isEditable={true}
                    imageClasses="rounded-circle profile-image"
                    editClasses="profile-edit-icon"
                    onImageUpload={setImageFile}
                    placeholderImg={APP_CONFIG.IMAGES.AVATAR_PLACEHOLDER}
                    imageToPreview={ProfileImage?.OriginalUrl}
                />
              </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="4" className="mt-auto input-field-con">
                <Label>{typography.NAME}</Label>
                <div className="mb-4">
                    <TextInput
                        placeholder={typography.ENTER_NAME}
                        name={"name"}
                        type="text"
                        className="hide-default-icon"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        // autoFocus
                    />
                    <FormikErrorText
                    formikInstance={formik} 
                    fieldName={"name"} />
                </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="4" className="mt-auto input-field-con">
                <Label>{typography.EMAIL}</Label>
                <div className="mb-4">
                    <TextInput
                        placeholder={typography.ENTER_EMAIL}
                        name={"email"}
                        type="email"
                        className="hide-default-icon"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        // autoFocus
                    />
                    <FormikErrorText 
                    formikInstance={formik} 
                    fieldName={"email"} />
                </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="4" className="mt-auto current-pass-con">
                <Label>{typography.CURRENT_PASSWORD}</Label>
                <div className="mb-4">
                    <PasswordInput
                        placeholder={typography.ENTER_PASSWORD}
                        className="hide-default-icon password-input-field"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />
                    <FormikErrorText
                    formikInstance={formik} 
                    fieldName={"password"} />
                </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="4" className="mt-auto input-field-con-password">
                <Label>{typography.NEw_PASSWORD}</Label>
                <div className="mb-4">
                    <PasswordInput
                        placeholder={typography.ENTER_NEW_PASSWORD}
                        className="hide-default-icon"
                        name="newPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                    />
                    <FormikErrorText
                    formikInstance={formik} 
                    fieldName={"newPassword"} />
                </div>
              </Col>
              <Col xs="12" md="6" lg="6" xl="4" className="mt-auto input-field-con-password">
                <Label>{typography.REENTER_PASSWORD}</Label>
                <div className="mb-4">
                    <PasswordInput
                        placeholder={typography.ENTER_REENTER_PASSWORD}
                        className="hide-default-icon"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                    />
                    <FormikErrorText
                    formikInstance={formik} 
                    fieldName={"confirmPassword"} />
                </div>
              </Col>
            </Row>
           </div>
            </form>
        </Container>
        <ConfirmationModal
          isOpen={updatedConfirmation}
          hasCustomConfig
          size="md"
          onNo={() => setUpdateConfirmation(false)}
          onYes={() => updateButtonHandler()}
          customTitle="Are you sure you want to update your profile?"
          customYesBtnText="Yes"
          customNoBtnText="No"
          customImageSrc={ModalSuccessImage}
      />
      </div>
    </>
  )
}